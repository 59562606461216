@media only screen and (min-width: 769px) {
  #review_title {
    max-width: 80%;
  }
  .comments {
    width: 86%;
  }
}

#mainCommentsVisual {
  position: relative;
  bottom: 25px;
}

@media only screen and (max-width: 768px) {
  .review {
    padding: 30px;
  }
}
