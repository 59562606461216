.greenSquare {
  width: 100%;
  height: 150px;
  background-color: rgb(25, 53, 48);
}

.redButton {
  background-color: rgb(216, 35, 23);
}

table {
  text-overflow: ellipsis;
  width: 70%;
  position: absolute;
  left: 50%;
  text-align: center;
  transform: translate(-50%, 0%);
  border-collapse: collapse;
  font-family: "Hiragino Kaku Gothic Pro";
  font-weight: 600;
}

table,
thead,
tbody,
tr {
  display: block;
  width: 100%;
}
tr {
  display: table;
  table-layout: fixed;
}

tbody tr {
  border-bottom: 2px solid #d2b48c;
}

tbody {
  height: 90%;
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  overflow: auto;
  background-color: rgba(255, 255, 255, 0.702);
}

thead {
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  background-color: rgb(170, 115, 34);
  color: white;
  border: 0px;
  font-size: 16px;
  border-bottom: 2px solid #d2b48c;
}

thead th {
  text-align: center;
}

tbody tr:nth-child(even) {
  background-color: rgba(255, 255, 255, 0);
  border-bottom: 2px solid #d2b48c;
}

tbody tr th {
  background-color: rgb(25, 53, 48);
  color: white;
  border: 0px;
  font-size: 16px;
}

td {
  font-size: 16px;
}

#prefecture {
  width: 30%;
}
#theater {
  width: 40%;
}
#release {
  width: 30%;
}

td[headers="prefecture"] {
  width: 30%;
}

td[headers="theater"] {
  width: 40%;
}

td[headers="release"] {
  width: 30%;
}

tbody {
  border: 0px;
}

tbody a {
  color: black;
  text-decoration: underline;
}

.phone-number {
  font-size: 14px;
}

.remarks {
  font-size: 13px;
  color: #d82317;
}

@media only screen and (max-width: 768px) {
  .topTheaterSection {
    display: block;
    width: 100%;
    position: relative;
    top: 30px;
  }

  #roadshow {
    height: 70%;
    width: auto;
    margin-left: 20px;
    position: absolute;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
  }

  .moviePoster {
    width: 33%;
    display: block;
    margin: 20px;
  }
  .theater-info {
    position: absolute;
    top: 0px;
    width: 100%;
    float: right;
  }
  #theater-comment {
    position: absolute;
    top: 167px;
    left: 40%;
    margin-left: 10px;
    margin-right: 20px;
  }
  #mubichike {
    width: 30%;
    position: absolute;
    top: 210px;
    left: 40%;
    margin-left: 10px;
    margin-right: 20px;
  }

  #theater-price {
    display: inline-block;
    position: absolute;
    top: 255px;
    left: 40%;
    margin-left: 10px;
    margin-right: 20px;
  }
  .smallComment {
    font-size: 11px;
    position: absolute;
    top: 300px;
    left: 40%;
    margin-left: 10px;
    margin-right: 20px;
  }
  .redButton {
    font-size: 16px;
    font-weight: 700;
    position: absolute;
    top: 305px;
    right: 5%;
    width: 50%;
    margin-left: 20px;
    span {
      line-height: 1.5;
      display: inline-block;
    }
  }

  table {
    width: 100%;
    margin-top: 40px;
  }

  thead {
    font-size: 11px;
  }

  tbody tr th {
    font-size: 12px;
  }

  td {
    font-size: 12px;
  }

  .phone-number {
    font-size: 11px;
  }

  .remarks {
    font-size: 11px;
    color: #d82317;
  }
}

@media only screen and (max-width: 400px) {
  #roadshow {
    height: 70%;
  }
  #mubichike {
    width: 50%;
    position: absolute;
    top: 230px;
    left: 40%;
    margin-right: 20px;
  }

  .redButton {
    position: absolute;
    top: 320px;
  }

  table {
    margin-top: 135px;
  }
}

@media only screen and (min-width: 769px) {
  .theater {
    width: 86%;
  }
  .topTheaterSection {
    background-color: white;
    display: block;
    margin-left: auto;
    margin-right: auto;
    padding: 20px;
    width: 90%;
    position: relative;
    top: 20px;
  }
  .moviePoster {
    width: 12%;
    display: block;
  }
  .greenSquare {
    width: 50%;
    height: 40px;
    background-color: rgb(25, 53, 48);
    position: absolute;
    top: 20px;
    left: 16%;
  }
  .theater-info {
    width: 60%;
    position: absolute;
    left: 16%;
    top: 0px;
  }
  #pc-roadshow {
    width: 90%;
    margin-left: 20px;
    position: absolute;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
  }

  #theater-comment {
    font-size: 18px;
    position: absolute;
    top: 75px;
  }
  #mubichike {
    height: 25px;
    width: auto;
    position: absolute;
    top: 105px;
  }
  #theater-price {
    font-size: 18px;
    position: absolute;
    top: 103px;
    margin-left: 160px;
  }

  .smallComment {
    font-size: 14px;
    position: absolute;
    top: 130px;
  }
  .redButton {
    font-size: 18px;
    font-weight: 700;
    line-height: 1.5;
    width: 25%;
    position: absolute;
    top: 20%;
    right: 20px;
  }

  @media only screen and (max-width: 1000px) {
    #theater-comment {
      font-size: 16px;
    }
    #mubichike {
      height: 20px;
      width: auto;
    }
    #theater-price {
      font-size: 15px;
      margin-left: 130px;
    }

    .smallComment {
      font-size: 12px;
    }
  }

  .bottomTheaterSection {
    display: block;
    margin-top: 250px;
    position: relative;
    top: 0px;
    margin-top: 0px;
  }

  #theaterTitle {
    width: auto;
    height: 25px;
    position: absolute;
    left: 17%;
    margin-top: 30px;
  }

  #theaterTitle_en {
    width: 10%;
    height: auto;
    position: absolute;
    top: 85px;
    left: 17%;
  }

  table {
    position: absolute;
    top: 150px;
    width: 100%;
  }
  #prefecture {
    width: 15%;
  }
  #theater {
    width: 70%;
  }
  #release {
    width: 15%;
  }

  td[headers="prefecture"] {
    width: 15%;
  }

  td[headers="theater"] {
    width: 70%;
  }

  td[headers="release"] {
    width: 15%;
  }
}
