.minor-staff-text-en {
  height: 16px;
  width: auto;
  margin-top: 10px;
  margin-bottom: 20px;
}

.dirt-staff {
  position: absolute;
  left: 0px;
  z-index: -1;
  width: 70%;
}
.staff-description {
  margin-top: 80px;
  position: relative;
  margin-bottom: 100px;
}

.staff-top-button {
  position: absolute;
  right: 50px;
  bottom: 50px;
  width: 10%;
  cursor: pointer;
}

@media only screen and (max-width: 768px) {
  .staff-description {
    margin-top: 50px;
    margin-bottom: 0px;
  }
}

@media only screen and (min-width: 769px) {
  .staff {
    width: 86%;
  }

  #staff-text {
    width: 86%;
  }
}
