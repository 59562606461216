#director-title {
  width: 100%;
  position: absolute;
  left: 0px;
}

#comment_heading {
  line-height: 1.75;
  font-weight: 700;
  z-index: 20;
}

.comment {
  line-height: 1.75;
  font-weight: 700;
  z-index: 20;
}

#machine {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 40%;
}

.director-text {
  position: relative;
  top: 70px;
  z-index: 20;
}

.white-square {
  width: 100%;
  height: auto;
  background-color: rgba(255, 255, 255, 0.5);
}

@media only screen and (max-width: 768px) {
  #director-image {
    padding-top: 80px;
  }
  .white-square {
    border-top: 5px solid black;
    padding: 20px;
    display: block;
    margin-left: -15px;
    margin-right: -15px;
    margin-bottom: 20px;
    width: auto;
  }
  .filmography {
    width: 75%;
    margin-top: 0px;
    margin-bottom: 50px;
  }
  #filmography {
    display: none;
    padding-bottom: 30px;
  }

  .button-wrapper {
    padding: 10px;
    margin-left: 5px;
    margin-right: 5px;
  }

  #comment_heading {
    font-size: 16px;
  }

  .comment {
    font-size: 14px;
  }
  #director-button {
    margin-bottom: 30px;
  }

  .director-profile {
    width: 70%;
  }
  .director-top-button {
    position: absolute;
    right: 0px;
    bottom: 0px;
    width: 20%;
    cursor: pointer;
  }
}

@media only screen and (min-width: 769px) {
  .director {
    width: 86%;
  }
  #director-title-en {
    position: relative;
    right: 30%;
  }

  #director-title-jp {
    position: relative;
    right: 30%;
  }

  #director-text {
    position: absolute;
    top: 20%;
    left: 40px;
  }

  #director-text-jp {
    position: absolute;
    top: 30%;
    left: 40px;
  }
  #director-dirt {
    position: absolute;
    top: 30%;
    left: 40px;
  }

  #director-text-en {
    position: absolute;
    top: 55%;
    left: 40px;
  }

  #director-pc {
    margin-bottom: 40px;
  }

  .white-square {
    border-left: 4px solid black;
    padding: 20px;
    margin-bottom: 100px;
  }

  .comment {
    font-size: 16px;
  }

  #machine {
    position: relative;
    top: 50%;
    margin: -50px auto 0;
    width: 70%;
  }
  .filmography {
    width: 75%;
    margin-left: 30px;
  }

  .director-top-button {
    width: 10%;
    position: absolute;
    right: 50px;
    bottom: 50px;
    z-index: 20;
    cursor: pointer;
  }
}
