.sticky {
  position: fixed;
  top: 0;
  z-index: 50;
}

.topIntro {
  background-color: white;
  height: 60px;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
}

.title_jp {
  height: 20px;
  width: auto;
  position: absolute;
  top: 10px;
  left: 50%;
  transform: translate(-50%, 0%);
}

.title {
  font-size: 17px;
  font-family: superclarendon, sans-serif;
  color: rgb(25, 53, 48);
  line-height: 1.2;
  text-align: center;
  position: absolute;
  top: 32px;
  left: 50%;
  transform: translate(-50%, 0%);
}

.double-border {
  border: 2px solid black;
  margin: 10px;
  z-index: 10;
}
.double-border:before {
  background: none;
  border: 1px solid black;
  content: "";
  display: block;
  position: absolute;
  top: 4px;
  left: 4px;
  right: 4px;
  bottom: 4px;
  pointer-events: none;
  z-index: 5;
}

.left-intro-image {
  padding-top: 18px;
  padding-bottom: 18px;
}
.right-intro-image {
  padding-top: 18px;
  padding-bottom: 18px;
}

.intro-text {
  width: 90%;
  position: relative;
  left: 5%;
  margin-top: 40px;
  margin-bottom: 40px;
}

.footer {
  position: relative;
  top: 100px;
}

.top-button {
  position: absolute;
  right: 20px;
  width: 30%;
  cursor: pointer;
  z-index: 10;
}

.grass-top-right {
  width: 23px;
  position: absolute;
  right: 22px;
  top: 22px;
  padding-bottom: 10px;
}
.grass-top-left {
  width: 23px;
  position: absolute;
  left: 22px;
  top: 22px;
  padding-bottom: 10px;
}
.grass-bottom-right {
  width: 23px;
  position: absolute;
  right: 22px;
  bottom: 22px;
  padding-top: 10px;
}
.grass-bottom-left {
  width: 23px;
  position: absolute;
  left: 22px;
  bottom: 22px;
  z-index: 10;
  padding-top: 10px;
}

@media only screen and (max-width: 768px) {
  .middleIntro {
    position: relative;
    bottom: 0px;
    display: block;
  }
  #mainIntroVisualSubtext {
    position: absolute;
    right: -45px;
    transform: translate(-50%, 0%);
    width: 26%;
  }
  .double-border {
    position: relative;
    top: 0px;
    padding-top: 55px;
    padding-bottom: 55px;
    padding-left: 22px;
    padding-right: 22px;
  }

  .dirt-top-right {
    width: 60%;
    position: absolute;
    right: 0px;
    top: 0px;
  }

  .dirt-bottom-left {
    width: 60%;
    position: absolute;
    left: 0px;
    bottom: 0%;
  }

  .double-border-text {
    font-size: 14px;
  }

  .left-intro-image {
    padding: 0;
  }
}

@media only screen and (min-width: 769px) {
  .intro {
    width: 86%;
  }
  #mainIntroVisualSubtext {
    position: absolute;
    right: 0px;
    top: 30px;
    transform: translate(-50%, 0%);
    width: 9%;
  }
  .smallSquare {
    width: 20%;
    height: 90px;
    opacity: 0.92;
    background-color: white;
    position: absolute;
    top: 40px;
    left: 20%;
  }

  .title_jp {
    position: absolute;
    top: 25px;
    left: 50%;
    z-index: 20;
    height: 25px;
    width: auto;
  }

  .title {
    font-size: 15px;
    font-family: superclarendon, sans-serif;
    color: rgb(25, 53, 48);
    line-height: 1.2;
    text-align: center;
    position: absolute;
    top: 50px;
    left: 50%;
    z-index: 20;
  }
  .wrapper {
    padding: 10px;
    position: relative;
    bottom: 100px;
    background-image: url(../../../assets/img/intro/pattern.png);
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }
  .double-border-up {
    width: 98%;
    margin-left: auto;
    margin-right: auto;
    position: relative;
  }
  .double-border {
    padding-left: 55px;
    padding-right: 55px;
    padding-top: 50px;
    padding-bottom: 50px;
  }
  .double-border-text {
    padding-top: 10px;
  }
  .left-intro-image {
    width: 48%;
    margin-right: 10px;
  }
  .right-intro-image {
    width: 48%;
    float: right;
    margin-left: 10px;
  }
  .intro-text {
    height: 60px;
    width: auto;
    position: relative;
    bottom: 100px;
  }
  .footer {
    position: relative;
    top: 0px;
  }

  .top-button {
    position: absolute;
    right: 20px;
    width: 10%;
    z-index: 10;
  }
  .grass-top-right {
    width: 22px;
    position: absolute;
    right: 22px;
    top: 22px;
    padding-bottom: 10px;
  }
  .grass-top-left {
    width: 22px;
    padding-bottom: 10px;
  }
  .grass-bottom-right {
    width: 22px;
    position: absolute;
    right: 22px;
    bottom: 22px;
    padding-top: 10px;
    z-index: 10;
  }
  .grass-bottom-left {
    width: 22px;
    padding-top: 10px;
    z-index: 10;
  }
  .dirt-top-right {
    width: 30%;
    position: absolute;
    right: 0px;
    top: 0px;
    z-index: 0;
  }

  .dirt-top-left {
    width: 8%;
    position: absolute;
    top: 10px;
    left: 10px;
  }
  .dirt-bottom-left {
    width: 30%;
    position: absolute;
    bottom: 0px;
    left: 0%;
  }
  .dirt-bottom-right {
    width: 12%;
    position: absolute;
    bottom: 0px;
    right: 0%;
  }
}
