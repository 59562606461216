@media only screen and (max-width: 768px) {
  #gokuhi {
    position: absolute;
    left: 20px;
    bottom: -20px;
    width: 26%;
  }
  #wife_of_a_spy {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 50%;
    position: relative;
    bottom: 25px;
  }
}

@media only screen and (min-width: 769px) {
  .story {
    width: 86%;
  }
  #gokuhi {
    position: absolute;
    left: 90px;
    top: -50px;
    width: 8%;
    z-index: 20;
  }
  #wife_of_a_spy {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 20%;
    position: relative;
    bottom: 0px;
  }
}
