.cast-title {
  width: 30%;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.cast-title-jp {
  height: 22px;
  width: auto;
  margin-left: auto;
  margin-right: auto;
  display: block;
}

.cast-title-en {
  font-size: 18px;
  font-family: superclarendon, sans-serif;
  color: rgb(25, 53, 48);
  line-height: 1.2;
  text-align: center;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.cast-pc {
  width: 95%;
}
.cast-image {
  padding-top: 40px;
  padding-bottom: 20px;
}

.character-text {
  height: 21px;
  width: auto;
}

.actor-text-jp {
  height: 45px;
  width: auto;
  margin-top: 10px;
}

.dirt-cast {
  width: 70%;
  position: absolute;
  left: 0px;
  z-index: -1;
}

.actor-text-en {
  height: 23px;
  width: auto;
  margin-top: 10px;
  margin-bottom: 30px;
}

.cast-text {
  font-weight: 400;
  line-height: 1.75;
}
.grass {
  width: 100%;
  height: auto;
  margin-top: 10px;
}

.minor-cast-text-group {
  position: relative;
  top: 35%;
  margin-left: 14px;
}
.minor-cast-text {
  width: 35%;
  height: auto;
}

.minor-actor-text-jp {
  height: auto;
  width: 90%;
  margin-top: 5px;
}
.minor-dirt-cast {
  width: 100%;
  position: absolute;
  top: 30%;
  right: 0%;
  z-index: -1;
}

.minor-actor-text-en {
  height: auto;
  width: 95%;
  margin-top: 5px;
  margin-bottom: 30px;
}

.minor-cast {
  width: 100%;
  margin-top: 30px;
}

#minosuke-en {
  width: 55%;
}

#kusakabe {
  width: 65%;
}

#hyunri-jp {
  width: 50%;
}

#hyunri-en {
  width: 40%;
}

#nozaki {
  width: 60%;
}

#takashi-en {
  width: 85%;
}

.button {
  border-radius: 10px;
  background-color: rgb(25, 53, 48);
  width: 100%;
  margin: auto;
  margin-top: 20px;
  margin-bottom: 20px;
  display: block;
}

.button:hover,
.button:focus {
  background-color: rgb(25, 53, 48);
}

.profile-button {
  width: 40%;
  padding: 10px;
}

.down-arrow {
  margin-left: auto;
  margin-right: auto;
  margin-top: 10px;
  width: 5%;
  display: block;
}
.up-arrow {
  margin-left: auto;
  margin-right: auto;
  margin-top: 10px;
  width: 5%;
  display: none;
}

.cast-profile {
  display: none;
}

.minor-cast-description {
  font-size: 18px;
  line-height: 1.75;
}

@media only screen and (max-width: 768px) {
  .top-cast-button {
    position: absolute;
    right: 0px;
    width: 30%;
    margin: 40px;
    cursor: pointer;
  }
  #dirt-bottom-left-cast {
    z-index: 0;
  }

  .cast-text {
    font-size: 14px;
  }
  .cast-wrapper {
    margin-top: 60px;
  }
  .minor-dirt-cast {
    position: absolute;
    top: 25%;
  }
}

@media only screen and (min-width: 769px) {
  .cast {
    width: 86%;
  }
  .cast-title {
    width: 10%;
    display: block;
    margin-left: auto;
    margin-right: auto;
    position: absolute;
    left: 15%;
    margin-bottom: 10px;
  }

  .cast-title-jp {
    height: 22px;
    width: auto;
    margin-left: auto;
    margin-right: auto;
    display: block;
  }
  .cast-wrapper {
    padding: 10px;
    position: relative;
    background-image: url(../../../assets/img/intro/pattern.png);
    width: 98%;
    margin-left: auto;
    margin-right: auto;
  }
  .cast-member {
    margin-top: 80px;
  }
  .cast-description {
    padding-left: 20px;
    padding-right: 0px;
    position: relative;
  }

  .actor-text-jp {
    height: 45px;
    width: auto;
    margin-top: 10px;
  }

  .dirt-cast {
    width: 70%;
    position: absolute;
    left: 0px;
    top: 5%;
    z-index: -1;
  }

  .actor-text-en {
    height: 23px;
    width: auto;
    margin-top: 10px;
  }

  .cast-text {
    font-size: 16px;
  }

  #grass {
    width: 100%;
    height: auto;
  }

  .minor-cast {
    width: 85%;
    margin-top: 0px;
  }

  .minor-cast-member {
    position: relative;
    top: 10px;
    margin-right: 10px;
  }

  .minor-dirt-cast {
    width: 90%;
    position: absolute;
    top: 25%;
    left: 0%;
    z-index: -1;
  }

  .minor-cast-text {
    width: 23%;
    height: auto;
    margin-top: 10px;
    display: inline-block;
  }

  .minor-actor-text-jp {
    height: auto;
    width: 60%;
    margin-top: 0px;
    margin-bottom: 2px;
    display: inline-block;
  }

  .minor-actor-text-en {
    height: auto;
    width: 63%;
    display: inline-block;
  }

  #minosuke-en {
    width: 35%;
  }

  #kusakabe {
    width: 35%;
  }

  #hyunri-jp {
    width: 28%;
    margin-top: 0px;
  }

  #hyunri-en {
    width: 24%;
  }

  #nozaki {
    width: 37%;
  }

  #takashi-en {
    width: 58%;
  }

  .minor-cast-description {
    width: 90%;
    float: left;
  }

  .top-cast-button {
    float: right;
    width: 10%;
    position: absolute;
    right: 30px;
    bottom: 40px;
    z-index: 20;
    cursor: pointer;
  }
}
