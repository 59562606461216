.hamburgerMenu {
  width: 60px;
  height: 60px;
  float: right;
  border: none;
  display: block;
  background-color: transparent;
  cursor: pointer;
  margin-left: auto;
  z-index: 100;
  &__wrap {
    position: fixed;
    top: 13px;
    right: 10px;
    width: 40px;
    height: 15px;
    z-index: 100;
  }
  &--span {
    display: block;
    position: absolute;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: black;
    border-radius: 0.5px;
    &:nth-of-type(1) {
      top: 0;
    }
    &:nth-of-type(2) {
      top: 7px;
    }
    &:nth-of-type(3) {
      bottom: 0;
    }
  }
  &--text {
    height: 8.5px;
    width: auto;
    display: inline-block;
    position: fixed;
    top: 38px;
    right: 10px;
    z-index: 100;
  }
}

.closeMenu {
  position: absolute;
  top: 0px;
  right: 0px;
  width: 60px;
  height: 60px;
  border: none;
  background-color: #182f2b;
  cursor: pointer;
  z-index: 2;
  &__wrap {
    position: absolute;
    top: 8px;
    right: 23px;
    width: 30px;
    height: 30px;
  }
  &--span {
    position: absolute;
    left: 10px;
    width: 100%;
    height: 1px;
    background-color: white;
    border-radius: 1px;
    &:nth-of-type(1) {
      top: 0;
      transform: translateY(14px) rotate(-45deg);
    }
    &:nth-of-type(2) {
      bottom: 0;
      transform: translateY(-14px) rotate(45deg);
    }
  }
  &--text {
    width: 50%;
    position: relative;
    top: 15px;
    left: 17px;
    display: block;
  }
}

.menu {
  li {
    padding-top: 10px;
    max-height: 60px;
  }
}

.side-nav {
  background-color: white;
  margin-bottom: 0;
  float: left;
  position: fixed;
  left: 0;
  top: 0;
  overflow-y: auto;
  overflow-x: hidden;
  height: 100% !important;
}

.small-menu {
  a {
    width: 200px;
  }
}

a {
  display: inline-block;
}

.youtube iframe {
  position: relative;
  top: 80px;
  overflow: hidden;
  margin: auto;
  display: block;
  border: #f3dfca 1px solid;
  iframe {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
  }
}

@media only screen and (max-width: 850px) {
  .youtube iframe {
    width: 89.33333vw;
    height: 50.26667vw;
  }
}

#trailerModal {
  background-image: linear-gradient(
    0deg,
    rgb(0, 0, 0) 0%,
    rgb(24, 47, 43) 100%
  );
}

@media only screen and (max-width: 768px) {
  .side-nav {
    width: 100%;
  }
  .jp-large-text {
    height: 17px;
    width: auto;
    display: inline-block;
  }

  .jp-small-text {
    height: 13px;
    width: auto;
    display: inline-block;
  }

  .svg {
    display: inline-block;
    margin-right: 0px;
    margin-left: 20px;
    margin-top: 20px;
  }

  li:nth-child(10) {
    position: relative;
    bottom: 40px;
    left: 145px;
  }

  li:nth-child(11) {
    position: relative;
    bottom: 35px;
    left: 3px;
  }

  li:nth-child(12) {
    position: relative;
    bottom: 85px;
    left: 135px;
  }
}

@media only screen and (min-width: 769px) {
  .sidebar {
    width: 14%;
  }
  #top {
    width: 40%;
  }

  #staff {
    width: 50%;
  }
  #comments {
    width: 100%;
  }
  #theater-page {
    width: 55%;
  }
  #trailer {
    width: 40%;
  }
  .jp-large-text {
    height: auto;
    width: 30%;
    margin-left: 8%;
    margin-top: 2%;
  }

  .jp-small-text {
    height: auto;
    width: 40%;
    display: inline-block;
    padding-right: 0px;
  }

  @media only screen and (min-width: 1100px) {
    #top {
      width: 28%;
    }

    #staff {
      width: 40%;
    }

    #comments {
      width: 94%;
    }
    #theater-page {
      width: 52%;
    }
    #trailer {
      width: 37%;
    }
    .jp-large-text {
      width: 26%;
    }

    .jp-small-text {
      width: 46%;
    }
    #facebook {
      position: relative;
      left: 65px;
      bottom: 45px;
    }

    #twitter {
      position: relative;
      left: 65px;
      bottom: 45px;
    }
  }

  .svg {
    display: inline-block;
    margin-right: 0px;
    margin-left: 20px;
    position: relative;
    top: 10px;
  }

  #facebook {
    position: relative;
    left: 60px;
    bottom: 45px;
  }

  #twitter {
    position: relative;
    left: 60px;
    bottom: 45px;
  }

  .side-nav {
    width: 14%;
  }

  li:nth-child(1) {
    margin-top: 30px;
  }
}
