html {
  text-align: justify;
  overflow-y: scroll;
}

body {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-feature-settings: "palt";
  background-image: url(../../../assets/img/intro/pattern.png);
  height: 100%;
  font-family: "Noto Sans JP", sans-serif;
  font-weight: 500;
  font-size: 18px;
  line-height: 1.75;
  letter-spacing: 0.5px;
}

p {
  line-height: 1.75;
}

.container {
  position: relative;
}

#trailer_title {
  width: 90%;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

@media only screen and (max-width: 768px) {
  html {
    background-image: url(../../../assets/img/intro/pattern.png);
  }

  body {
    font-size: 14px;
  }

  #title {
    position: absolute;
    top: 25px;
    left: 32%;
    width: 19%;
  }

  #mainVisualSubtext {
    position: absolute;
    top: 15px;
    left: 15px;
    width: 15%;
  }

  .top {
    position: absolute;
    top: 0px;
    background-color: black;
  }
  .square {
    width: 100%;
    height: auto;
    margin-bottom: 60px;
    background-image: linear-gradient(
      0deg,
      rgb(0, 0, 0) 0%,
      rgb(24, 47, 43) 100%
    );
    display: block;
  }

  .square:after {
    content: "";
    display: block;
    padding-bottom: 100%;
  }

  #venezia {
    position: absolute;
    bottom: 3.5%;
    right: 0px;
    width: 80%;
  }

  #mystery {
    position: absolute;
    right: 6%;
    bottom: 5.5%;
    width: 5%;
  }

  #title_director {
    position: absolute;
    bottom: 3%;
    right: 7%;
    width: 60%;
  }
  #aoi_yu {
    width: 40%;
    position: absolute;
    top: 8%;
    left: 50%;
    transform: translate(-50%, 0%);
  }

  #higashide {
    width: 80%;
    position: absolute;
    top: 15%;
    left: 50%;
    transform: translate(-50%, 0%);
  }

  #kurosawa {
    width: 23%;
    position: absolute;
    top: 21%;
    left: 50%;
    transform: translate(-50%, 0%);
  }

  #kyakuhon {
    width: 70%;
    position: absolute;
    top: 28%;
    left: 50%;
    transform: translate(-50%, 0%);
  }

  #credit {
    width: 95%;
    position: absolute;
    top: -10px;
    left: 5px;
  }

  #footer {
    width: 90%;
    position: absolute;
    left: 50%;
    bottom: 30px;
    transform: translate(-50%, 0%);
  }
  .smallSquare {
    width: 89.5%;
    height: 24%;
    background-color: white;
    position: absolute;
    bottom: 20%;
    left: 50%;
    transform: translate(-50%, 0%);
  }

  #premiere {
    width: 70%;
    background-color: white;
    position: absolute;
    bottom: 25%;
    left: 50%;
    transform: translate(-50%, 0%);
  }

  .reveal_outer {
    display: block;
    width: 100%;
    height: 50%;
    position: absolute;
    top: 50%;
  }
  #trailer_title {
    position: absolute;
    top: 0%;
    width: 80%;
    left: 30%;
    transform: translate(-30%, 0%);
  }
  #trailer_title_director {
    position: absolute;
    top: 25%;
    left: 5%;
    width: 70%;
    display: block;
    margin: auto;
  }
  #trailer-mystery {
    position: absolute;
    top: 22.5%;
    left: 70%;
    width: 7%;
  }
}
@media only screen and (min-width: 500px) {
  #trailer_title {
    top: 40%;
  }
  #trailer_title_director {
    position: absolute;
    top: 73%;
  }
  #trailer-mystery {
    position: absolute;
    top: 70%;
    left: 71%;
    width: 5%;
  }
}

@media only screen and (min-width: 769px) {
  .top {
    width: 80%;
    background-color: black;
  }

  #mainVisualSubtext {
    position: absolute;
    top: 3.5%;
    left: 7%;
    width: 9%;
  }

  #title {
    width: 12%;
    position: absolute;
    top: 3.5%;
    left: 27%;
  }

  #mystery {
    position: absolute;
    right: 6%;
    bottom: 13.5%;
    width: 4%;
  }

  #title_director {
    position: absolute;
    bottom: 11.5%;
    right: 7.5%;
    width: 35%;
  }

  #venezia {
    position: absolute;
    top: 0%;
    right: 0%;
    width: 45%;
  }

  #aoi_yu {
    width: 20%;
    position: absolute;
    bottom: 30%;
    left: 50%;
    transform: translate(-50%, 0%);
  }

  #higashide {
    width: 50%;
    position: absolute;
    bottom: 26%;
    left: 50%;
    transform: translate(-50%, 0%);
  }

  #kurosawa {
    width: 10%;
    position: absolute;
    bottom: 22%;
    left: 50%;
    transform: translate(-50%, 0%);
  }

  #kyakuhon {
    width: 40%;
    position: absolute;
    bottom: 19%;
    left: 50%;
    transform: translate(-50%, 0%);
  }

  #credit {
    width: 33%;
    position: absolute;
    top: 6%;
    right: 1%;
  }

  #footer {
    width: 45%;
    position: absolute;
    bottom: 10px;
    right: 2%;
  }
}

.rightIndex {
  background-color: rgb(25, 53, 48);
  width: 6%;
}

#ten {
  width: 40%;
  display: block;
  margin-top: 30px;
  margin-left: auto;
  margin-right: auto;
}

#sixteen {
  width: 40%;
  display: block;
  margin-bottom: 5px;
  margin-left: auto;
  margin-right: auto;
}
#slash {
  width: 35%;
  margin-bottom: 5px;
  margin-top: 5px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
#shinjuku {
  width: 41%;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
